import { SupportedSMSCountries } from '@eppendorf/vnls-utils';

import { AutocompleteSelectOption } from '$components/autocomplete';

export const supportedSMSCountriesOptions: AutocompleteSelectOption[] =
  SupportedSMSCountries.map(({ country, code }): AutocompleteSelectOption => {
    const plusCountryCode = `+${code}`;

    return {
      label: `${country} (${plusCountryCode})`,
      value: `${plusCountryCode}`,
    };
  });

export const getSMSCountry = (countrySelected: string) =>
  supportedSMSCountriesOptions.find((country) => country.value === countrySelected);
