import {
  Button,
  Checkbox,
  Dialog,
  Icon,
  IconSizeClasses,
} from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FormEvent, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { VerifyPhoneScreens } from '$features/show-user/verify-phone-number-dialog/types';
import { useStartPhoneVerification, useUpdateUser } from '$features/users/users.api';

export interface AcceptTermsAndConditionsScreenProps {
  selectedUser: User;
  goToNextScreen: (screen: VerifyPhoneScreens) => void;
}

export function AcceptTermsAndConditionsScreen({
  selectedUser,
  goToNextScreen,
}: AcceptTermsAndConditionsScreenProps) {
  const { mutateAsync } = useUpdateUser();
  const { mutateAsync: mutateAsyncStartPhoneVerification } = useStartPhoneVerification();

  const { t } = useTranslation();

  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const acceptTermsAndConditions = async (e: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const userPhoneNumber = selectedUser.phoneNumbers?.[0];
      const { firstName } = selectedUser;
      const { lastName } = selectedUser;

      if (userPhoneNumber && firstName && lastName) {
        await mutateAsync({
          id: selectedUser.id,
          firstName,
          lastName,
          acceptedTermsAndConditions: true,
        });

        await mutateAsyncStartPhoneVerification({
          phoneNumber: {
            number: userPhoneNumber.number,
            countryCode: userPhoneNumber.countryCode,
          },
        });
      }
    } catch {
      setIsLoading(false);
      goToNextScreen(VerifyPhoneScreens.UnableToSendVerificationCode);
      // eslint-disable-next-line no-useless-return -- disables test error output
      return;
    }

    goToNextScreen(VerifyPhoneScreens.VerifyCodeScreen);
  };

  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };
  const selectedUserPhoneNumber = selectedUser.phoneNumbers?.[0];

  return (
    <form onSubmit={acceptTermsAndConditions}>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="phone"
          className="bg-blue-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('verifyPhoneNumber.termsAndConditionsDialog.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.termsAndConditionsDialog.verifyPhoneExplanation"
            values={{
              phoneNumber: `${selectedUserPhoneNumber?.countryCode} ${selectedUserPhoneNumber?.number}`,
            }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </Dialog.Description>
        <div className="flex flex__dir--row gap-m text-align-left m-bottom-l">
          <Checkbox onChange={() => handleOnChange()} checked={isChecked} />
          <div>
            <Trans
              className="font-size-l font-weight-regular"
              i18nKey="verifyPhoneNumber.termsAndConditionsDialog.termsAndConditionsCopy"
              components={{
                termsLink: (
                  <Link to="/terms.html" className="color-blue-500" target="_blank" />
                ),
                policyLink: (
                  <Link
                    to="/privacypolicy.html"
                    className="color-blue-500"
                    target="_blank"
                  />
                ),
              }}
            />
          </div>
        </div>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="secondary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
        <Button disabled={!isChecked || isLoading} type="submit">
          {t('verifyPhoneNumber.termsAndConditionsDialog.sendVerificationButton')}
        </Button>
      </Dialog.Actions>
    </form>
  );
}
