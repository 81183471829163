import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { useTranslation } from 'react-i18next';

import { useHistoryChartsConfig } from '$features/monitoring/history-charts/useHistoryChartsConfig';

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: type this
export function CustomTooltip({ active, payload }: any) {
  const { t } = useTranslation();
  const { showThresholdRefs, chartColors } = useHistoryChartsConfig();
  const bucket = payload.length && payload[0].payload;
  const refPoints = [
    {
      label: t('monitoringDetail.historyChartsDetail.setPointLabel'),
      value: bucket?.setPoint,
      color: chartColors.setPointColor,
      show: Number.isFinite(bucket?.setPoint) && showThresholdRefs,
    },
    {
      label: t('monitoringDetail.historyChartsDetail.highAlertLabel'),
      value: bucket?.alertHigh,
      color: chartColors.alertHighLowColor,
      show: Number.isFinite(bucket?.alertHigh) && showThresholdRefs,
    },
    {
      label: t('monitoringDetail.historyChartsDetail.lowAlertLabel'),
      value: bucket?.alertLow,
      color: chartColors.alertHighLowColor,
      show: Number.isFinite(bucket?.alertLow) && showThresholdRefs,
    },
  ];

  const getProcessValueColor = () => {
    const value = bucket.processValue;
    if (bucket?.alertHigh && value >= bucket?.alertHigh)
      return chartColors.alertHighLowColor;
    if (bucket?.alertLow && value <= bucket?.alertLow)
      return chartColors.alertHighLowColor;
    return '#60ba05';
  };

  if (active && bucket && payload && payload.length) {
    return (
      <div className="custom-tooltip flex flex__dir--column gap-m font-size-s bg-white p-m border-radius-l bs-shadow-xl color-gray-800">
        <p className="label flex flex__ai--center flex__jc--space-between w-full font-size-xxl font-weight-semibold">
          <span />
          {Number.isFinite(bucket.processValue) && (
            <span style={{ color: getProcessValueColor() }}>
              {bucket.processValue} {bucket.unit}
            </span>
          )}
        </p>
        <div>
          {refPoints.map(
            (item) =>
              item.show && (
                <p
                  key={item.value}
                  className="label flex flex__ai--center flex__jc--space-between w-full color-gray-800"
                >
                  <span>{item.label} </span>
                  <span style={{ color: item.color }}>
                    {item.value} {bucket.unit}
                  </span>
                </p>
              ),
          )}
        </div>

        <p className="flex flex__ai--center gap-s">
          <span>
            <Icon name="calendar" size={IconSizeClasses.XXSmall} />
          </span>
          <span>{bucket.fullDate}</span>
        </p>
      </div>
    );
  }

  return null;
}
