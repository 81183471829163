import { NotificationRule } from '@eppendorf/vnls-notification-service-types';
import { QueryClient } from '@tanstack/react-query';
import { ReactElement } from 'react';

import { isFeatureEnabledInCache } from "$shared/feature-toggle/is-feature-enabled";
import { prepopulateCache } from '$shared/utils/react-query.utils';

import { appConfigQueryKey, createGetFeatureToggleQuery } from '$components/app-config/app-config.api';
import { NotificationDetailSidecar } from '$features/notifications/detail/notifications-detail-sidecar';
import { createGetNotificationRuleByIdQuery } from '$features/notifications/notification-rules.api';

export async function notificationRuleDetailLoader(
  qClient: QueryClient,
  notificationId: string,
): Promise<null> {
  // TODO: remove this code when feature flag will be removed.
  const isNotificationServiceV2Enabled = await isFeatureEnabledInCache(
    qClient,
    'addUpdateDeleteNotificationRulesYggdrasil',
    createGetFeatureToggleQuery(),
    [appConfigQueryKey, 'feature-toggles']
  );

  return prepopulateCache<NotificationRule>(
    qClient,
    createGetNotificationRuleByIdQuery(notificationId, isNotificationServiceV2Enabled),
  );
}

export function NotificationRuleDetailPage(): ReactElement {
  return <NotificationDetailSidecar />;
}
