import { Dialog } from '@eppendorf/vnls-react-components';
import { User } from '@eppendorf/vnls-user-tenant-utils';
import { FunctionComponentElement, useState } from 'react';

import { DeleteUserDialogWrapperProps } from '$features/delete-user/delete-user-dialog/delete-user-dialog-wrapper';
import { VerifyPhoneScreens } from '$features/show-user/verify-phone-number-dialog/types';
import { VerifyPhoneNumberDialogContent } from '$features/show-user/verify-phone-number-dialog/verify-phone-number-dialog-content';

export interface VerifyPhoneNumberDialogWrapperProps {
  isOpen?: boolean;
  onOpenChange?: (open: boolean) => void;
  selectedUser: User;
}

export function VerifyPhoneNumberDialogWrapper({
  isOpen = false,
  onOpenChange,
  selectedUser,
}: VerifyPhoneNumberDialogWrapperProps): FunctionComponentElement<DeleteUserDialogWrapperProps> {
  function handleOpenChange(open: boolean): void {
    if (onOpenChange) onOpenChange(open);
  }

  const [activeScreen, setActiveScreen] = useState<VerifyPhoneScreens>(
    VerifyPhoneScreens.AcceptTermsAndConditionsScreen,
  );

  function handleScreenChange(screen: VerifyPhoneScreens): void {
    setActiveScreen(screen);
  }

  return (
    <Dialog
      isOpen={isOpen}
      onOpenChange={(open) => handleOpenChange(open)}
      className="w-max-4xl"
    >
      <VerifyPhoneNumberDialogContent
        selectedUser={selectedUser}
        activeScreen={activeScreen}
        setActiveScreen={(screen: VerifyPhoneScreens) => handleScreenChange(screen)}
      />
    </Dialog>
  );
}
