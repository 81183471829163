/* eslint-disable react/jsx-props-no-spreading -- Need to maintain some cleanliness here */
import { Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import classNames from 'classnames';
import React, { forwardRef, ForwardedRef, ReactElement, useState } from 'react';
import ReactSelect, { SingleValue } from 'react-select';

import { SelectOptionComponent } from './autocomplete-select-option';
import { AutocompleteProps, AutocompleteSelectOption } from './autocomplete.definition';

import './autocomplete.scss';

export const Autocomplete = forwardRef(
  (
    {
      value,
      onChange,
      ariaLabelledBy,
      disabled,
      options,
      placeholder,
      className,
      ariaLabel,
      noOptionsMessage,
      name,
      menuPosition = 'bottom',
    }: AutocompleteProps,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- react-select makes problems
    ref: ForwardedRef<any>,
  ): ReactElement<AutocompleteProps> => {
    // needs to be controlled to allow fine-granular control of event bubbling
    const [isMenuOpen, setMenuOpen] = useState(false);

    const multiSelectClasses = classNames('multi-select', className, {
      'multi-select--disabled': disabled,
      // If we have a value selected and the menu is opened use our custom css to show this
      'multi-select__option--selected': value && isMenuOpen,
    });

    function handleChange(newValue: SingleValue<AutocompleteSelectOption>): void {
      onChange(newValue as AutocompleteSelectOption);
    }

    function handleMenuOpen(): void {
      setMenuOpen(true);
    }

    function handleMenuClose(): void {
      setMenuOpen(false);
    }

    return (
      <ReactSelect
        classNames={{
          option: () => 'multi-select__option',
          input: () => 'multi-select__input',
          noOptionsMessage: () => 'multi-select__no-options-message',
        }}
        name={name}
        className={multiSelectClasses}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        hideSelectedOptions={false}
        isDisabled={disabled}
        noOptionsMessage={noOptionsMessage}
        onChange={(newValue) => handleChange(newValue)}
        options={options}
        menuIsOpen={isMenuOpen}
        onMenuClose={() => handleMenuClose()}
        onMenuOpen={() => handleMenuOpen()}
        placeholder={placeholder}
        value={value}
        menuPlacement={menuPosition}
        ref={ref}
        components={{
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          Option: ({ isSelected, setValue, data, ...props }) => (
            <SelectOptionComponent
              setValue={setValue as never}
              data={data}
              isSelected={isSelected}
              {...props}
            />
          ),
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          DropdownIndicator: ({ selectProps: { menuIsOpen } }) => (
            <Icon
              className={`${disabled ? 'bg-gray-500' : 'bg-blue-500'} multi-select__dropdown-icon`}
              name={`${menuIsOpen ? 'up' : 'down'}`}
              size={IconSizeClasses.XSmall}
            />
          ),
          // eslint-disable-next-line react/no-unstable-nested-components -- accepted
          IndicatorSeparator: () => <div className="multi-select__separator" />,
        }}
      />
    );
  },
);
/* eslint-enable react/jsx-props-no-spreading */
