import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

export function UnableToSendVerificationCode() {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="failed"
          className="bg-red-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('verifyPhoneNumber.unableToSendVerificationCode.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.unableToSendVerificationCode.description"
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </Dialog.Description>
        <div className="flex flex__dir--row gap-xs text-align-left m-bottom-l">
          {t('verifyPhoneNumber.unableToSendVerificationCode.errorCopy')}
          <a
            aria-label="contact support"
            href="https://www.eppendorf.com/service-support/"
            className="font-weight-regular color-blue-500"
            target="_blank"
            style={{ display: 'inline' }}
            rel="noreferrer"
          >
            {t('verifyPhoneNumber.unableToSendVerificationCode.contactSupport')}
          </a>
        </div>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="primary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
