import {
  NotificationRule,
  PaginationResponse,
} from '@eppendorf/vnls-notification-service-types';
import { QueryClient } from '@tanstack/react-query';

import { isFeatureEnabledInCache } from '$shared/feature-toggle/is-feature-enabled';
import { prepopulateCache } from '$shared/utils/react-query.utils';

import { appConfigQueryKey, createGetFeatureToggleQuery } from '$components/app-config/app-config.api';
import { NotificationRuleList } from '$features/notification-rule-list';
import { createNotificationRulesQueryWithQueryParams } from '$features/notifications/notification-rules.api';

export async function notificationRulesLoader(queryClient: QueryClient): Promise<null> {
  // TODO: remove this code when feature flag will be removed.
  const isNotificationServiceV2Enabled = await isFeatureEnabledInCache(
    queryClient,
    'addUpdateDeleteNotificationRulesYggdrasil',
    createGetFeatureToggleQuery(),
    [appConfigQueryKey, 'feature-toggles']
  );

  return prepopulateCache<{ data: NotificationRule[]; pagination: PaginationResponse }>(
    queryClient,
    createNotificationRulesQueryWithQueryParams({ page: 1, pageSize: 10 }, isNotificationServiceV2Enabled),
  );
}

export function NotificationsPage() {
  return <NotificationRuleList />;
}
