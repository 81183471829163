import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { TabNavigationRoute, TabsNavigation } from '$components/tabs/tabs-navigation';

export function MonitoringDetailNavigation(): ReactElement {
  const { t } = useTranslation();

  const monitoringDetailSidecarRoutes: TabNavigationRoute[] = [
    {
      label: t('monitoringDetail.parameters'),
      path: 'parameters',
      icon: 'parameter',
    },
    {
      label: t('monitoringDetail.history-charts'),
      path: 'history-charts',
      icon: 'charts',
    },
    {
      label: t('monitoringDetail.events'),
      path: 'events',
      icon: 'events',
    },
    {
      label: t('monitoringDetail.export'),
      path: 'export',
      icon: 'save',
    }
  ];

  return <TabsNavigation routes={monitoringDetailSidecarRoutes} />;
}
