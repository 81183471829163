import { Device } from '@eppendorf/vnls-inventory-service-types';
import { useTranslation } from 'react-i18next';

import { DeviceCountrySelect } from '$features/devices/device-information/device-country-select';
import { EditInfoItem } from '$features/devices/device-information/edit-info-item';
import { InfoItem } from '$features/devices/device-information/info-item';
import {
  resolveDeviceMetaCountryValue,
  resolveDeviceMetaDataValue,
} from '$features/devices/devices.utils';

export function DeviceAddress({
  data,
  editMode,
}: {
  data: Device['address'] | undefined;
  editMode: boolean;
}) {
  const { t } = useTranslation();

  return (
    <>
      <div className="sub-title m-y-xxl">
        {t('deviceDetail.deviceInformation.address')}
      </div>
      {!editMode && (
        <>
          <InfoItem
            label={t('deviceDetail.deviceInformation.company')}
            value={resolveDeviceMetaDataValue(data?.organization)}
          />
          <InfoItem
            label={`${t('deviceDetail.deviceInformation.street')} 1`}
            value={resolveDeviceMetaDataValue(data?.street1)}
          />
          <InfoItem
            label={`${t('deviceDetail.deviceInformation.street')} 2`}
            value={resolveDeviceMetaDataValue(data?.street2)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.postcode')}
            value={resolveDeviceMetaDataValue(data?.postcode)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.city')}
            value={resolveDeviceMetaDataValue(data?.city)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.state')}
            value={resolveDeviceMetaDataValue(data?.state)}
          />
          <InfoItem
            label={t('deviceDetail.deviceInformation.country')}
            value={resolveDeviceMetaCountryValue(
              data?.country,
              t('deviceDetail.deviceInformation.countryISOCodeNotFound', {
                code: data?.country,
              }),
            )}
          />
        </>
      )}
      {editMode && (
        <>
          <EditInfoItem
            id="organization"
            label={t('deviceDetail.deviceInformation.company')}
          />
          <EditInfoItem
            id="street1"
            label={`${t('deviceDetail.deviceInformation.street')} 1`}
          />
          <EditInfoItem
            id="street2"
            label={`${t('deviceDetail.deviceInformation.street')} 2`}
          />
          <EditInfoItem
            id="postcode"
            label={t('deviceDetail.deviceInformation.postcode')}
          />
          <EditInfoItem id="city" label={t('deviceDetail.deviceInformation.city')} />
          <EditInfoItem id="state" label={t('deviceDetail.deviceInformation.state')} />
          <DeviceCountrySelect
            id="country"
            label={t('deviceDetail.deviceInformation.country')}
          />
        </>
      )}
    </>
  );
}
