import {
  NotificationRule,
  NotificationRuleCreateRequestBody,
  NotificationRulesGetResponse,
  NotificationRulesQueryParams,
} from '@eppendorf/vnls-notification-service-types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import { fetcher } from '$shared/fetch';
import {
  createNotificationRulePath,
  createPathWithQueryParams,
  getNotificationRuleBasePath,
  objectToURLSearchParams,
} from '$shared/utils/api-paths';

export const notificationRulesQueryKey = 'notification-rules';
export const addedNotificationRuleQueryKey = 'added-notification-rule';

// TODO remove useYggdrasil when feature flag will be removed.
export const createNotificationRulesQueryWithQueryParams = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
  queryParams: NotificationRulesQueryParams,
  useYggdrasil: boolean | undefined,
) => ({
  queryKey: [notificationRulesQueryKey, ...objectToURLSearchParams(queryParams).values()],
  queryFn: async () =>
    fetcher.get<NotificationRulesGetResponse>(
      createPathWithQueryParams(getNotificationRuleBasePath(useYggdrasil), queryParams),
    ),
  keepPreviousData: true,
});

export const createGetNotificationRuleByIdQuery = (
  id: string,
  useYggdrasil: boolean | undefined,
) => ({
  queryKey: [notificationRulesQueryKey, id],
  queryFn: async () =>
    fetcher.get<NotificationRule>(createNotificationRulePath(id, useYggdrasil)),
  enabled: !!id,
});

export const useGetNotificationRule = (id: string, useYggdrasil: boolean | undefined) =>
  useQuery(createGetNotificationRuleByIdQuery(id, useYggdrasil));

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- we don't know the actual type
export const useNotificationRulesWithQueryParams = (
  queryParams: NotificationRulesQueryParams,
  useYggdrasil: boolean | undefined,
) => useQuery(createNotificationRulesQueryWithQueryParams(queryParams, useYggdrasil));

export const useAddedNotificationRule = () =>
  useQuery({
    queryKey: [addedNotificationRuleQueryKey],
    queryFn: () => [],
  });

export const useAddNotificationRule = (useYggdrasil: boolean | undefined) => {
  const addNotificationRule = (notificationRule: NotificationRuleCreateRequestBody) =>
    fetcher.post<NotificationRuleCreateRequestBody, NotificationRule>(
      getNotificationRuleBasePath(useYggdrasil),
      notificationRule,
    );

  const queryClient = useQueryClient();
  return useMutation<
    AxiosResponse<NotificationRule>,
    AxiosError,
    NotificationRuleCreateRequestBody
  >(addNotificationRule, {
    // This is sufficient for now. When we tackle performance we can utilise
    // optimistic updates or saving another single device request by mutating data instead
    onSuccess: () => {
      queryClient.invalidateQueries([notificationRulesQueryKey]);

      queryClient.setQueryData([addedNotificationRuleQueryKey], {});
    },
  });
};

export const useUpdateNotificationRule = (useYggdrasil: boolean | undefined) => {
  const updateNotificationRule = (rule: NotificationRule) =>
    fetcher.patch<NotificationRule, NotificationRule>(
      createNotificationRulePath(rule.id, useYggdrasil),
      rule,
    );

  const queryClient = useQueryClient();
  return useMutation<NotificationRule, Error, NotificationRule>(updateNotificationRule, {
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries([notificationRulesQueryKey]);

      queryClient.setQueryData([notificationRulesQueryKey, variables.id], data);
    },
  });
};
