import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { FunctionComponentElement, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAutoFocus } from '$shared/custom-hooks/useAutoFocus';
import { capitalize } from '$shared/utils/string.utils';

import { useDeviceType } from '$components/app-config/useDeviceType';

export interface AddedScreenProps {
  device?: Device;
}

export function SuccessScreen({
  device,
}: AddedScreenProps): FunctionComponentElement<AddedScreenProps> {
  const { t } = useTranslation();
  const pairLaterButtonRef = useRef<null | HTMLButtonElement>(null);

  useAutoFocus(pairLaterButtonRef);

  const deviceType = capitalize(
    useDeviceType(device) || t('deviceDetail.fallbackHeadlineNameEmpty'),
  );

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="success"
          className="bg-green-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>{t('addDevice.isAdded')}</Dialog.Title>
        <span>
          <Trans
            i18nKey="addDevice.successInfo"
            values={{
              serialNumber: device?.serialNumber,
              type: deviceType,
            }}
            components={{ strong: <span className="font-weight-bold" /> }}
          />
        </span>
      </div>
      <Dialog.Actions className="m-top-xxxl">
        <Dialog.Close>
          <Button className="btn--solid">{t('shared.close')}</Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
