import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils';

export enum VerifyPhoneScreens {
  'AcceptTermsAndConditionsScreen',
  'VerifyCodeScreen',
  'SuccessVerificationScreen',
  'ErrorVerificationScreen',
  'UnableToSendVerificationCode',
}

export interface StartVerificationBody {
  phoneNumber: Omit<PhoneNumber, 'status'>;
}

export interface ReportVerificationBody extends StartVerificationBody {
  verificationCode: string;
}
