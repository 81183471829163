import { Button, Dialog, Icon, IconSizeClasses } from '@eppendorf/vnls-react-components';
import { PhoneNumber } from '@eppendorf/vnls-user-tenant-utils/dist/cjs/src/types/shared';
import { Trans, useTranslation } from 'react-i18next';

export interface AcceptTermsAndConditionsScreenProps {
  selectedUserPhoneNumber: PhoneNumber;
}
export function SuccessVerifyPhoneNumber({
  selectedUserPhoneNumber,
}: AcceptTermsAndConditionsScreenProps) {
  const { t } = useTranslation();

  return (
    <>
      <div className="flex flex__dir--column flex__ai--center text-align-center">
        <Icon
          name="success"
          className="bg-green-500 m-bottom-xxl"
          size={IconSizeClasses.XLarge}
        />
        <Dialog.Title>
          {t('verifyPhoneNumber.successVerifiedPhoneNumber.title')}
        </Dialog.Title>
        <Dialog.Description className="m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.successVerifiedPhoneNumber.description"
            values={{
              phoneNumber: `${selectedUserPhoneNumber?.countryCode} ${selectedUserPhoneNumber?.number}`,
            }}
            components={{ bold: <span className="font-weight-bold" /> }}
          />
        </Dialog.Description>
        <div className="flex flex__dir--row gap-s text-align-left m-bottom-l">
          <Trans
            className="font-size-l font-weight-regular"
            i18nKey="verifyPhoneNumber.successVerifiedPhoneNumber.receiveSMSCopy"
          />
        </div>
      </div>
      <Dialog.Actions className="m-top-xxl">
        <Dialog.Close>
          <Button variant="secondary" className="btn--solid">
            {t('shared.close')}
          </Button>
        </Dialog.Close>
      </Dialog.Actions>
    </>
  );
}
