import { User, UpdateUserBody } from '@eppendorf/vnls-user-tenant-utils';
import { ReactElement, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

export type EditUserForm = UpdateUserBody;

export interface FormWrapperProps {
  children: ReactElement;
  user?: User;
}

export function FormWrapper({ children, user }: FormWrapperProps): ReactElement {
  const methods = useForm<EditUserForm>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      methods.reset(
        {
          firstName: user.firstName,
          lastName: user.lastName,
          roles: user.roles,
          phoneNumbers: user.phoneNumbers,
        },
        { keepDirtyValues: true },
      );
    }
  }, [user]);

  /* eslint-disable-next-line react/jsx-props-no-spreading -- spread is needed for react-hook-form */
  return <FormProvider {...methods}>{children}</FormProvider>;
}
