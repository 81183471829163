// TODO: update CreateUserBody in @eppendorf/vnls-user-tenant-utils to match User
// import { InviteUserForm } from '$features/invite-user/invite-user-dialog/screens/invite-screen';
type InviteUserForm = {
  firstName?: string;
  lastName?: string;
  email: string;
};
export function displayUser(user?: InviteUserForm): string {
  if (user?.firstName && user?.lastName) {
    return `${user?.firstName} ${user?.lastName}, ${user?.email}`;
  }

  if (user?.firstName || user?.lastName) {
    return `${user?.firstName || user?.lastName}, ${user?.email}`;
  }

  return user?.email || '';
}
