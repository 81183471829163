// eslint-disable-next-line eslint-comments/disable-enable-pair -- !
/* eslint-disable security/detect-object-injection -- ! */
import { Button, Dialog } from '@eppendorf/vnls-react-components';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAccessControlGuard } from '$shared/custom-hooks/useAccessControlGuard';

import { CancelEditDialog } from '$components/cancel-edit-dialog/cancel-edit-dialog';
import { Divider } from '$components/divider/divider';
import { ErrorDialogRetry } from '$components/error-dialog-retry/error-dialog-retry';
import { FeatureIsEnabled } from '$components/feature-toogle/feature-is-enabled';
import { useSidecarContext } from '$components/sidecar/sidecar-context-hook';
import { DeviceAddress } from '$features/devices/device-information/components/device-address';
import { GeneralInformation } from '$features/devices/device-information/components/device-general-information';
import { DeviceLocation } from '$features/devices/device-information/components/device-location';
import { useGetDevice, useUpdateDevice } from '$features/devices/devices.api';

export function DeviceInformation() {
  const [searchParams] = useSearchParams();
  const refForScroll = useRef<HTMLDivElement>(null);
  const submitRef = useRef<HTMLFormElement>(null);

  const [isValid, setIsValid] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isRetryDialogOpen, setIsRetryDialogOpen] = useState(false);
  const methods = useFormContext();
  const navigate = useNavigate();

  const {
    mutateAsync,
    error: updateDeviceError,
    isLoading: isUpdateDeviceLoading,
    isSuccess: isUpdateDeviceSuccess,
  } = useUpdateDevice();

  let { data } = useGetDevice({
    manufacturer: searchParams.get('manufacturer') ?? '',
    serialNumber: searchParams.get('serialNumber') ?? '',
  });

  const { isSidecardClosing, isCancelDialogOpen, setIsCancelDialogOpen } =
    useSidecarContext();

  const hasUpdateDeviceMetadataPermissions = useAccessControlGuard([
    'UPDATE_LOCATION_INFO',
    'UPDATE_ADDRESS_INFO',
  ]);

  const onError = () => {
    setIsValid(false);
  };

  useEffect(() => {
    if (!updateDeviceError) {
      setIsRetryDialogOpen(true);
    }
  }, [updateDeviceError]);

  const resetToEditView = (keepFormData = false) => {
    setIsCancelDialogOpen(false);
    setEditMode(true);
    setIsSubmitted(false);
    if (!keepFormData) methods.reset({ ...data?.address, ...data?.location, ...data });
  };

  const resetToReadView = () => {
    setIsCancelDialogOpen(false);
    setEditMode(false);
    methods.reset(data);
  };
  useEffect(() => {
    if (updateDeviceError) {
      resetToEditView(true);
    }

    if (isUpdateDeviceSuccess) {
      resetToReadView();
    }
  }, [isUpdateDeviceSuccess, updateDeviceError]);

  const onSubmit = async (modifiedData: Record<string, string | number | object>) => {
    if (data) {
      try {
        setIsSubmitted(true);

        /* this is a workaround for a bug with the form with nested objects that prevents me from using '.' in ids
         to access formstate.errors to show validation messages in the edit-info-item component.
         So instead of working e.g. with address.street1 and having the result neatly assembled later on,
         I can use only street1 and have to manually assemble the address object, same for location. */
        let merged = data;
        if (!data.location) merged.location = {};
        if (!data.address) merged.address = {};
        Object.keys(modifiedData).forEach((key) => {
          if (
            key === 'site' ||
            key === 'building' ||
            key === 'room' ||
            key === 'position'
          ) {
            merged.location = { ...merged.location, [key]: modifiedData[key].toString() };
          } else if (
            key === 'organization' ||
            key === 'street1' ||
            key === 'street2' ||
            key === 'postcode' ||
            key === 'city' ||
            key === 'state' ||
            key === 'country'
          ) {
            merged.address = {
              ...merged.address,
              [key]: modifiedData[key] ? modifiedData[key].toString() : '',
            };
          } else if (key !== 'address' && key !== 'location') {
            /* merge remaining attributes, needed to exclude address and location keys as useform hook seems to populate these
            after 1 successful save and when editing again. Normally this would be exactly what we want, but due to the validation
             message bug, mentioned above, we need to avoid nested objects and therefore the above else if disregards the keys with objects
            */
            merged = { ...merged, [key]: modifiedData[key].toString() };
          }
        });

        data = await mutateAsync(merged);
        setEditMode(false);
      } catch {
        onError();
      }
    } else {
      setEditMode(false);
    }
  };

  function handleDialogOnOpenChange(): void {
    setIsCancelDialogOpen(false);
  }

  function handleDialogDiscard(): void {
    resetToReadView();

    if (isSidecardClosing) {
      navigate({
        search: '',
      });
    }
  }

  function handleDialogSaveChanges(): void {
    methods.handleSubmit(onSubmit)();
    if (isSidecardClosing) {
      navigate({
        search: '',
      });
    }
  }

  return (
    <>
      <form onSubmit={methods.handleSubmit(onSubmit)} ref={submitRef}>
        <div className="h-full" ref={refForScroll}>
          <GeneralInformation data={data} editMode={editMode} />
          <Divider />
          <DeviceLocation data={data?.location} editMode={editMode} />
          <Divider />
          <DeviceAddress data={data?.address} editMode={editMode} />
          <FeatureIsEnabled feature="updateDeviceMetadata">
            <div className="m-top-xxl flex flex__jc--flex-end">
              {hasUpdateDeviceMetadataPermissions &&
                !editMode &&
                data?.connectionStatus === 'paired' && (
                  <Button
                    size="small"
                    onClick={() => {
                      resetToEditView();
                      refForScroll.current?.scrollIntoView();
                    }}
                  >
                    {t('shared.edit')}
                  </Button>
                )}
              {editMode && (
                <div className="dialog-content__actions-wrapper m-top-xxl">
                  <Button
                    onClick={() => {
                      resetToReadView();
                    }}
                    variant="secondary"
                    size="small"
                    disabled={isUpdateDeviceLoading}
                  >
                    {t('shared.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    size="small"
                    disabled={(isSubmitted && !isValid) || isUpdateDeviceLoading}
                  >
                    {t('shared.save')}
                  </Button>
                </div>
              )}
            </div>
          </FeatureIsEnabled>
        </div>
      </form>

      {updateDeviceError && (
        <Dialog
          isOpen={isRetryDialogOpen}
          onOpenChange={(state) => setIsRetryDialogOpen(state)}
          hideCloseButton
        >
          <ErrorDialogRetry
            title={t('errors.errorOccurred')}
            message={t('errors.unexpected')}
            onTryAgain={() => {
              submitRef?.current?.dispatchEvent(
                new Event('submit', { cancelable: true, bubbles: true }),
              );
            }}
          />
        </Dialog>
      )}

      <CancelEditDialog
        isOpen={isCancelDialogOpen}
        onOpenChange={() => handleDialogOnOpenChange()}
        onDiscardChanges={() => handleDialogDiscard()}
        onSaveChanges={() => handleDialogSaveChanges()}
        isLoading={isUpdateDeviceLoading}
      />
    </>
  );
}
