import type { PhoneNumber, User } from '@eppendorf/vnls-user-tenant-utils';

import { AcceptTermsAndConditionsScreen } from '$features/show-user/verify-phone-number-dialog/screens/accept-terms-and-conditions-screen';
import { ErrorVerifyPhoneNumberScreen } from '$features/show-user/verify-phone-number-dialog/screens/error-verify-number-screen';
import { SuccessVerifyPhoneNumber } from '$features/show-user/verify-phone-number-dialog/screens/success-phone-verified-screen';
import { UnableToSendVerificationCode } from '$features/show-user/verify-phone-number-dialog/screens/unable-to-send-verification-code';
import { VerifyNumberScreen } from '$features/show-user/verify-phone-number-dialog/screens/verify-number-screen';
import { VerifyPhoneScreens } from '$features/show-user/verify-phone-number-dialog/types';

export interface VerifyPhoneNumberDialogContentProps {
  selectedUser: User;
  activeScreen: VerifyPhoneScreens;
  setActiveScreen: (screen: VerifyPhoneScreens) => void;
}

export function VerifyPhoneNumberDialogContent({
  selectedUser,
  activeScreen,
  setActiveScreen,
}: VerifyPhoneNumberDialogContentProps) {
  return (
    <>
      {activeScreen === VerifyPhoneScreens.AcceptTermsAndConditionsScreen && (
        <AcceptTermsAndConditionsScreen
          selectedUser={selectedUser}
          goToNextScreen={setActiveScreen}
        />
      )}
      {activeScreen === VerifyPhoneScreens.VerifyCodeScreen && (
        <VerifyNumberScreen
          selectedUser={selectedUser}
          goToNextScreen={setActiveScreen}
        />
      )}
      {activeScreen === VerifyPhoneScreens.SuccessVerificationScreen && (
        <SuccessVerifyPhoneNumber
          selectedUserPhoneNumber={selectedUser.phoneNumbers?.[0] as PhoneNumber}
        />
      )}

      {activeScreen === VerifyPhoneScreens.ErrorVerificationScreen && (
        <ErrorVerifyPhoneNumberScreen />
      )}

      {activeScreen === VerifyPhoneScreens.UnableToSendVerificationCode && (
        <UnableToSendVerificationCode />
      )}
    </>
  );
}
