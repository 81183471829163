import { Sidecar } from '@eppendorf/vnls-react-components';
import { ReactElement, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import {isFeatureEnabled} from "$shared/feature-toggle/is-feature-enabled";

import { LoadingErrorHint } from '$components/loading-error-hint/loading-error-hint';
import { NotificationRuleDetailWrapper } from '$features/notifications/detail/notification-detail-wrapper';
import { useGetNotificationRule } from '$features/notifications/notification-rules.api';

export function NotificationDetailSidecar(): ReactElement {
  const [searchParams] = useSearchParams();

  const [isSidecarOpen, setIsSidecarOpen] = useState(true);

  const navigate = useNavigate();

  // TODO remove useYggdrasil when feature flag will be removed.
  const useYggdrasilPath = isFeatureEnabled('addUpdateDeleteNotificationRulesYggdrasil');
  const { data } = useGetNotificationRule(searchParams.get('id') || '', useYggdrasilPath);

  function handleSidecarOpenChange(): void {
    setIsSidecarOpen(false);
    navigate({
      search: '',
    });
  }

  return (
    <Sidecar isOpen={isSidecarOpen} onOpenChange={() => handleSidecarOpenChange()}>
      <div className="flex flex__dir--column flex__ai--stretch">
        {!data && <LoadingErrorHint className="m-top-xl" />}
        {data && <NotificationRuleDetailWrapper data={data} />}
      </div>
    </Sidecar>
  );
}
