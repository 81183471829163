import { Device } from '@eppendorf/vnls-inventory-service-types';
import { Badge } from '@eppendorf/vnls-react-components';
import { Device as RealtimeDevice } from '@eppendorf/vnls-telemetry-and-events-types';
import i18next from 'i18next';
import { ReactElement } from 'react';

import { getConnectionStatusText } from '$features/devices/devices.utils';

export interface ConnectionStatusBadgeProps {
  status?: Device['connectionStatus'] | RealtimeDevice['connectionStatus'];
  bordered?: boolean;
}

export function ConnectionStatusBadge({
  status,
  bordered = false,
}: ConnectionStatusBadgeProps): ReactElement {
  switch (status) {
    case 'pending':
    case 'readyToPair':
      return (
        <Badge bordered={bordered} color="orange">
          {getConnectionStatusText(status)}
        </Badge>
      );
    case 'paired':
      return (
        <Badge bordered={bordered} color="green">
          {getConnectionStatusText(status)}
        </Badge>
      );
    case 'waitingForMetadata':
      return (
        <Badge bordered={bordered} color="orange">
          {getConnectionStatusText(status)}
        </Badge>
      );
    default:
      return (
        <Badge bordered={bordered}>
          {i18next.t('translation:activityStatus.offline')}
        </Badge>
      );
  }
}
