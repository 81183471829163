import cn from 'classnames';
import React from 'react';
import { FieldValues, useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getCountryName, countryOptions } from '$shared/utils/country.utils';

import { Autocomplete } from '$components/autocomplete';

import styles from './info-item.module.scss';

type ValueOf<T> = T[keyof T];

export function DeviceCountrySelect<T extends FieldValues>({
  label,
  id,
}: {
  label: string;
  id: ValueOf<T>;
}) {
  const { control } = useFormContext<T>();

  const { t } = useTranslation();

  return (
    <div className="flex m-bottom-l flex__ai--center">
      <div className={cn('font-weight-bold', styles.label)}>{label}</div>
      <div className={cn('color-gray-900', 'body-regular', styles.value)}>
        <Controller
          control={control}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <Autocomplete
              className="w-max-xxl"
              options={countryOptions}
              value={getCountryName(value)}
              onChange={(option) => onChange(option.value)}
              placeholder={t('deviceDetail.deviceInformation.countrySelectPlaceHolder')}
              noOptionsMessage={() =>
                t('deviceDetail.deviceInformation.noCountryOptions')
              }
              ariaLabelledBy="country"
              menuPosition="top"
            />
          )}
          name={id}
        />
      </div>
    </div>
  );
}
