import { Input } from '@eppendorf/vnls-react-components';
import { FieldPath, useFormContext } from 'react-hook-form';

import { getThresholdName } from '$features/monitoring/monitoring.utils';
import { ThresholdsFormProfile } from '$features/monitoring/parameters/thresholds-form';
import { ThresholdValueLabelProps } from '$features/monitoring/parameters/types';

export function ThresholdDynamicValueLabel({ realtimeData }: ThresholdValueLabelProps) {
  const { register, setValue, formState } = useFormContext<ThresholdsFormProfile>();

  const name = getThresholdName(realtimeData) as FieldPath<ThresholdsFormProfile>;

  return (
    <>
      <Input
        id={name}
        // eslint-disable-next-line security/detect-object-injection -- this is safe
        invalid={!!formState.errors[name]}
        className="w-max-xxs bg-white"
        // eslint-disable-next-line react/jsx-props-no-spreading -- It is safe to spread register
        {...register(name, {
          onChange: (event) => {
            const currentValue = event.target.value;
            // allow only whole numbers and minus sign
            const newValue = currentValue.replace(/(?!^-)\D/g, '');

            if (currentValue !== newValue) {
              setValue(name, newValue);
            }
          },
        })}
      />

      <span className="m-left-s">{realtimeData.unit}</span>
    </>
  );
}
